import React, { useCallback, useEffect, useRef, RefObject } from "react";
import { UAParser } from "ua-parser-js";

import { ReactComponent as Svg } from "assets/media/illustration-whale.svg";
import { ReactComponent as SvgFlippedX } from "assets/media/illustration-whale-flipped-x.svg";

type InstanceProps = {
    timeoutId: NodeJS.Timeout | null;
    ua: UAParser;
};

type Props = {
    flippedX?: boolean;
};

export const SvgWhale = (props: Props) => {
    const ref = useRef<SVGSVGElement>(null);
    const instance = useRef<InstanceProps>({
        timeoutId: null,
        ua: new UAParser(),
    });

    const cleanUp = () => {
        if (instance.current.timeoutId)
            clearTimeout(instance.current.timeoutId);
        instance.current.timeoutId = null;
    };

    const blink = useCallback(
        (
            ref: RefObject<SVGSVGElement>,
            duration: number,
            unblink?: boolean,
        ) => {
            if (!ref || !ref.current) return;

            const svg = ref.current;

            instance.current.timeoutId = setTimeout(() => {
                const elements: NodeListOf<SVGPathElement> = svg.querySelectorAll(
                    ".svg-eye-blink",
                );

                if (elements.length === 0) return;

                elements.forEach((e, i) => {
                    if (!e.hasAttribute("data-fill")) {
                        e.setAttribute("data-fill", "transparent");
                    }
                    let fill = e.getAttribute("fill") || "";
                    e.setAttribute("fill", e.getAttribute("data-fill") || "");
                    e.setAttribute("data-fill", fill);
                });

                blink(ref, unblink ? 100 : Math.random() * 5000, !unblink);
            }, duration);
        },
        [],
    );

    const followEyes = useCallback(
        (ref: RefObject<SVGSVGElement>, mouseX: number, mouseY: number) => {
            if (!ref || !ref.current) return;

            const svg = ref.current;

            const elements: NodeListOf<SVGPathElement> = svg.querySelectorAll(
                ".svg-eye-follow",
            );

            if (elements.length === 0) return;

            elements.forEach((e, i) => {
                const boundingBox = e.getBoundingClientRect();
                const eyeX =
                    boundingBox.left +
                    (boundingBox.right - boundingBox.left) / 2;
                const eyeY =
                    boundingBox.top +
                    (boundingBox.bottom - boundingBox.top) / 2;

                const rad =
                    Math.atan2(mouseY - eyeY, +(mouseX - eyeX)) - Math.PI / 4;

                e.setAttribute(
                    "style",
                    `transform-box:fill-box;transform:rotate(${rad}rad)`,
                );
            });
        },
        [],
    );

    useEffect(() => {
        if (ref) {
            blink(ref, Math.random() * 5000, true);

            window.addEventListener("mousemove", (event) => {
                if (instance.current.ua.getBrowser().name !== "Edge") {
                    followEyes(ref, event.clientX, event.clientY);
                }
            });
        }
    }, [ref, blink, followEyes]);

    useEffect(() => cleanUp);

    const SvgComponent = props.flippedX ? SvgFlippedX : Svg;

    return <SvgComponent ref={ref} className="svg-whale" />;
};
