import React, { Component } from "react";

const defaultProps = {
    isMenuOpen: false,
    toggleMenu: () => {},
};

export const AppContext = React.createContext(defaultProps);

export class AppProvider extends Component {
    static defaultProps = defaultProps;

    toggleMenu = () => {
        this.setState(() => ({ isMenuOpen: !this.state.isMenuOpen }));
    };

    state = {
        ...defaultProps,
        toggleMenu: this.toggleMenu,
    };

    render() {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppProvider;
