import React, { FunctionComponent } from "react";
import classNames from "classnames";

type Props = {
    correct?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick?: (correct: boolean) => void;
};

export const QuizAnswer: FunctionComponent<Props> = (props) => {
    const className = classNames("quiz-answer", {
        "quiz-answer--correct": props.selected && props.correct,
        "quiz-answer--incorrect": props.selected && !props.correct,
    });

    const handleClick = () => {
        if (props.onClick) props.onClick(!!props.correct);
    };

    return (
        <button
            className={className}
            onClick={handleClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};
