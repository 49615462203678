import React, { useRef, useEffect } from "react";
// import { SocialWall } from "components/SocialWall";

import { SvgWave } from "components/SvgWave";
import { SvgWhale } from "components/SvgWhale";
import { animate } from "./SocialBlock.animations";
import { GalleryGrid } from "../GalleryGrid/GalleryGrid";
import socialImage_1 from "../../assets/media/social/Jana_Mame i bebe - IG 1.png"
import socialImage_2 from "../../assets/media/social/Jana_Mame i bebe - IG 2.png"
import socialImage_3 from "../../assets/media/social/Jana_Mame i bebe - IG 3.png"
import socialImage_4 from "../../assets/media/social/Jana_Mame i bebe - IG 4.png"
import socialImage_5 from "../../assets/media/social/Jana_Mame i bebe - IG 5.png"
import socialImage_6 from "../../assets/media/social/Jana_Mame i bebe - IG 6.png"

const cardContent = [
    {
        cardImg: socialImage_1
    },{
        cardImg: socialImage_2
    },{
        cardImg: socialImage_3
    },{
        cardImg: socialImage_4
    },{
        cardImg: socialImage_5
    },{
        cardImg: socialImage_6
    }
]

export const SocialBlock = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref || !ref.current) return;
        animate(ref.current);
    }, [ref]);

    return (
        <div
            ref={ref}
            className="page-block social-block"
            id="kapljica-ljubavi"
        >
            <div className="social-block__preheader">
                <SvgWhale flippedX />
                <SvgWave />
            </div>
            <div className="social-block__container">
                <div className="wrapper">
                    <div className="social-block__decoration-left">
                        <img alt="" src="/assets/media/boce.png" />
                    </div>
                    <div className="social-block__decoration-right">
                        <img alt="" src="/assets/media/boce.png" />
                    </div>
                    <div className="social-block__tagline">
                        <span className="text-copy text--light">
                            #KapljicaLjubavi
                        </span>
                    </div>
                    <div className="social-block__title">
                        <h2 className="text-title text--light">Mame i bebe</h2>
                    </div>
                    <div className="social-block__wall">
                        <GalleryGrid cardProps={cardContent} />
                        {/* <SocialWall /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
