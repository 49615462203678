import React, { FunctionComponent, useCallback, useState } from "react";
import Slider from "react-slick";

import data from "assets/data/facts.json";
import { SvgPenguin } from "components/SvgPenguin";
import { SvgZebra } from "components/SvgZebra";

export const FactCarousel: FunctionComponent<any> = (props) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
    const [sliderRef, setSliderRef] = useState<Slider | null>(null);

    const ref = useCallback((node) => {
        setSliderRef(node);
    }, []);

    const onPreviousClick = () => {
        if (!sliderRef) return;

        sliderRef.slickPrev();

        if (currentSlideIndex - 1 < 0) {
            setCurrentSlideIndex(data.items.length - 1);
        } else {
            setCurrentSlideIndex(currentSlideIndex - 1);
        }
    };

    const onNextClick = () => {
        if (!sliderRef) return;

        sliderRef.slickNext();

        if (currentSlideIndex + 1 >= data.items.length) {
            setCurrentSlideIndex(0);
        } else {
            setCurrentSlideIndex(currentSlideIndex + 1);
        }
    };

    return (
        <div className="fact-carousel">
            <div className="fact-carousel__container">
                <Slider
                    ref={ref}
                    {...{
                        dots: false,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        fade: true,
                        lazyLoad: "ondemand",
                    }}
                >
                    {data.items.map((e, i) => (
                        <div className="fact" key={i}>
                            <div className="fact__image-decorations">
                                <SvgPenguin />
                                <SvgZebra />
                            </div>
                            <div className="fact__image-container">
                                <div className="fact__image">
                                    <div className="rotated-image">
                                        <img src={e.image} alt={e.title} />
                                    </div>
                                </div>
                            </div>
                            <div className="fact__content-container">
                                <div className="fact__content">
                                    <div className="fact__number">
                                        <span className="text-small text--primary">
                                            {i + 1}
                                        </span>
                                    </div>
                                    <div className="fact__title">
                                        <h3 className="text-subtitle text--dark">
                                            {e.title}
                                        </h3>
                                    </div>
                                    <div className="fact__copy">
                                        <p
                                            className="text-copy text--body"
                                            dangerouslySetInnerHTML={{
                                                __html: e.content,
                                            }}
                                        ></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="fact-carousel__controls">
                <button
                    className="fact-carousel__control-arrow"
                    onClick={onPreviousClick}
                >
                    <div className="icon icon--round-arrow-left"></div>
                </button>
                <div className="fact-carousel__control-indicator">
                    <span className="text-copy text--primary">
                        {currentSlideIndex + 1} - {data.items.length}
                    </span>
                </div>
                <button
                    className="fact-carousel__control-arrow"
                    onClick={onNextClick}
                >
                    <div className="icon icon--round-arrow-right"></div>
                </button>
            </div>
        </div>
    );
};
