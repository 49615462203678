import React from "react";
import { Quiz } from "components/Quiz/Quiz";

import data from "assets/data/quiz.json";

type Props = {};

export const QuizBlock = (props: Props) => (
    <div className="page-block quiz-block" id="kviz-znanja">
        <div className="quiz-block__wrapper">
            <div className="quiz-block__tagline">
                <span className="text-copy text--dark">{data.tagline}</span>
            </div>
            <div className="quiz-block__title">
                <h2 className="text-title text--dark">{data.title}</h2>
            </div>
            <div className="quiz-block__quiz-container">
                <div className="quiz-block__quiz-intro">
                    <div className="quiz-block__quiz-intro-title">
                        <h3 className="text-subtitle text--dark">
                            {data.content.title}
                        </h3>
                    </div>
                    <div className="quiz-block__quiz-intro-copy">
                        <p className="text-copy text--body">
                            {data.content.body}
                        </p>
                    </div>
                </div>
                <div className="quiz-block__quiz-app">
                    <Quiz>
                        {data.items &&
                            data.items.map((e, i) => (
                                <Quiz.Question
                                    key={i}
                                    label={
                                        <Quiz.Question.Label>
                                            {e.question}
                                        </Quiz.Question.Label>
                                    }
                                >
                                    {e.answers.map((f, j) => (
                                        <Quiz.Question.Answer
                                            key={j}
                                            correct={f.is_correct}
                                        >
                                            {f.label}
                                        </Quiz.Question.Answer>
                                    ))}
                                </Quiz.Question>
                            ))}
                    </Quiz>
                </div>
            </div>
        </div>
    </div>
);
