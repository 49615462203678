import React, { FunctionComponent } from "react";
import classNames from "classnames";

interface Props extends React.HTMLProps<HTMLElement> {
    tag: string;
}

export const Button: FunctionComponent<Props> = (props) => {
    const { tag, className, ...restProps } = props;

    return React.createElement(props.tag, {
        ...restProps,
        className: classNames.call(null, "button", className),
    });
};
