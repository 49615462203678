import React from "react";
import {iCard} from "../GalleryGrid/GalleryGrid";

interface Props {
    card: iCard;
}

export const GalleryGridItem = (props:Props) => {
    const { cardImg } = props.card;
    return (
        <div className="gallery-grid__card">
            <img src={cardImg} />
        </div>
    );
};
