import React from "react";

import { AdviceCarousel } from "components/AdviceCarousel";

import data from "assets/data/advice.json";

export const AdviceBlock = () => {
    return (
        <div className="page-block advice-block" id="savjeti">
            <div className="wrapper">
                <div className="advice-block__tagline">
                    <span className="text-copy text--dark">{data.tagline}</span>
                </div>
                <div className="advice-block__title">
                    <h2 className="text-title text--dark">{data.title}</h2>
                </div>
                <div className="advice-block__advice-carousel">
                    <AdviceCarousel />
                </div>
            </div>
        </div>
    );
};
