import React, {
    FunctionComponent,
    ReactNode,
    useState,
    ReactElement,
} from "react";
import { QuizAnswer } from "components/QuizAnswer";

type Props = {
    label: ReactNode;
    onSelect?: (correct: boolean) => void;
    children: ReactNode;
};

type AnswerProps = {
    correct?: boolean;
};

const Answer: FunctionComponent<AnswerProps> = (props) => (
    <QuizAnswer {...props} />
);

const Label: FunctionComponent = (props) => (
    <p className="text-question text--dark">{props.children}</p>
);

export function QuizQuestion(props: Props) {
    const [answerIndex, setAnswerIndex] = useState(-1);

    return (
        <div className="quiz-question">
            <div className="quiz-question__text">{props.label}</div>
            {props.children && (
                <ul className="quiz-question__answer-list">
                    {React.Children.map(
                        props.children,
                        (Child: ReactElement | ReactNode, i) => {
                            if (!React.isValidElement(Child)) return null;

                            return (
                                <li
                                    className="quiz-question__answer-list-item"
                                    key={i}
                                >
                                    {React.cloneElement(
                                        Child as ReactElement<any>,
                                        {
                                            onClick: (correct: boolean) => {
                                                setAnswerIndex(i);
                                                if (props.onSelect) {
                                                    props.onSelect(correct);
                                                }
                                            },
                                            selected:
                                                answerIndex >= 0 &&
                                                (answerIndex === i ||
                                                    (Child &&
                                                        Child.props &&
                                                        Child.props.correct)),
                                            disabled: answerIndex >= 0,
                                        },
                                    )}
                                </li>
                            );
                        },
                    )}
                </ul>
            )}
        </div>
    );
}

QuizQuestion.Answer = Answer;
QuizQuestion.Label = Label;
