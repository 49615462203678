import React from "react";

import { ReactComponent as Svg } from "assets/media/wave-clip.svg";

export const SvgWave = () => {
    return (
        <>
            <svg
                className="a11y-hide svg-wave-data"
                width="320"
                height="96"
                viewBox="0 0 320 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <clipPath>
                        <path
                            id="wave-clip-stage-1"
                            d="M0 2.73211V96H320V0C317.371 11.054 302.578 9.93995 279 13C261.241 15.3049 236.335 3.47998 220 6C200 9.08534 194.334 12.6425 185.438 16.3191C163.561 25.3598 134.156 27.2796 112.5 16.5C105.521 13.0259 95.3422 6 82 6C71.3255 6 61.6124 7.95869 52.7642 9.74299C30.0011 14.3333 12.9617 36.7694 0 2.73211Z"
                            fill="#2AA4E9"
                        />
                        <path
                            id="wave-clip-stage-2"
                            d="M0 23V96H320V23C320 23 306 23 282.5 19C259 15 234 5.99997 206 9C178 12 180 25 158 27.5C136 30 127 30 117.5 27.5C108 25 87 17 79 13C71 9 49.3482 4.21567 40.5 5.99997C17.7369 10.5903 0 23 0 23Z"
                            fill="#2AA4E9"
                        />
                        <path
                            id="wave-clip-stage-3"
                            d="M0 23V96H320V23C320 23 314 28.5001 290.5 24.5C267 20.5 247 16 219 19C191 22.0001 190 28.5 168 31C146 33.5 134.5 33.5 117.5 31C100.5 28.5 89.5 19 77 19C64.5 19 47.3482 25.7157 38.5 27.5C15.7369 32.0904 0 23 0 23Z"
                            fill="#2AA4E9"
                        />
                        <path
                            id="wave-clip-stage-4"
                            d="M0 2.73211V96H320V0C317.371 11.054 302.578 28.9399 279 32C261.241 34.3049 236.335 22.48 220 25C200 28.0853 194.334 31.6425 185.438 35.3191C163.561 44.3598 134.156 46.2796 112.5 35.5C105.521 32.0259 95.3422 25 82 25C71.3255 25 61.6124 26.9587 52.7642 28.743C30.0011 33.3333 12.9617 36.7694 0 2.73211Z"
                            fill="#2AA4E9"
                        />
                    </clipPath>
                </defs>
            </svg>
            <Svg className="svg-shape-wave" />
        </>
    );
};
