import React from "react";

import { FactCarousel } from "components/FactCarousel";

import data from "assets/data/facts.json";

export const FactsBlock = () => {
    return (
        <div className="page-block facts-block" id="zanimljive-cinjenice">
            <div className="wrapper">
                <div className="facts-block__tagline">
                    <span className="text-copy text--dark">{data.tagline}</span>
                </div>
                <div className="facts-block__title">
                    <h2 className="text-title text--dark">{data.title}</h2>
                </div>
                <div className="facts-block__fact-carousel">
                    <FactCarousel />
                </div>
            </div>
        </div>
    );
};
