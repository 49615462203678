import React, { useContext } from "react";
import { AppContext } from "context/AppContext";

export const HamburgerListenerBehavior: React.FunctionComponent = () => {
    const appContext = useContext(AppContext);

    if (appContext.isMenuOpen) {
        document.body.classList.add("menu-open");
        const offset = window.pageYOffset;
        document.body.style.position = "fixed";
        document.body.style.top = -offset + "px";
    } else {
        const offset = -parseInt(document.body.style.top || "");
        document.body.style.position = "";
        window.scrollTo({ left: 0, top: offset });
    }

    return null;
};
