import React, { useRef, useEffect } from "react";

import { SvgWhale } from "components/SvgWhale";
import { SvgWave } from "components/SvgWave";
import { animate } from "./ForewordBlock.animations";

import data from "assets/data/foreword.json";

export const ForewordBlock = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref || !ref.current) return;
        animate(ref.current);
    }, [ref]);

    return (
        <div
            ref={ref}
            className="page-block foreword-block"
            id="potpora-strucnjaka"
        >
            <div className="foreword-block__preheader">
                <SvgWhale />
                <SvgWave />
            </div>
            <div className="foreword-block__container">
                <div className="wrapper">
                    {/* <div className="foreword-block__stamp-image">
                        <img alt="" src="/assets/media/stamp.svg" />
                    </div> */}
                    <div className="foreword-block__tagline">
                        <span className="text-copy text--light">
                            {data.tagline}
                        </span>
                    </div>
                    <div className="foreword-block__title">
                        <h2
                            className="text-title text--light"
                            dangerouslySetInnerHTML={{ __html: data.title }}
                        />
                    </div>
                    <div className="foreword-block__copy">
                        <ul className="decorated-list">
                            {data.list &&
                                data.list.map((e, i) => (
                                    <li
                                        className="decorated-list__item"
                                        key={i}
                                    >
                                        <p className="text-copy text--light">
                                            {e}
                                        </p>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="foreword-block__steps">
                        <ol className="stepped-list">
                            {data.steps &&
                                data.steps.map((e, i) => (
                                    <li className="stepped-list__item" key={i}>
                                        <p className="text-accent text--light">
                                            {e}
                                        </p>
                                    </li>
                                ))}
                        </ol>
                    </div>
                    <div className="foreword-block__image">
                        <img alt="" src="/assets/media/boce.png" />
                    </div>
                </div>
            </div>
        </div>
    );
};
