import React, { FunctionComponent, useCallback, useState } from "react";
import Slider from "react-slick";

import data from "assets/data/advice.json";

export const AdviceCarousel: FunctionComponent<any> = (props) => {
    const [sliderRef, setSliderRef] = useState<Slider | null>(null);

    const ref = useCallback((node) => {
        setSliderRef(node);
    }, []);

    const onPreviousClick = () => {
        if (!sliderRef) return;

        sliderRef.slickPrev();
    };

    const onNextClick = () => {
        if (!sliderRef) return;

        sliderRef.slickNext();
    };

    return (
        <div className="advice-carousel">
            <button
                className="advice-carousel__arrow-left"
                onClick={onPreviousClick}
            >
                <div className="icon icon--round-arrow-left"></div>
            </button>
            <div className="advice-carousel__container">
                <Slider
                    ref={ref}
                    {...{
                        dots: false,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        fade: true,
                        lazyLoad: "ondemand",
                    }}
                >
                    {data.items.map((e, i) => (
                        <div className="advice" key={i}>
                            <div className="advice__content-container">
                                <div className="advice__title">
                                    <h3 className="text-subtitle text--dark">
                                        {e.title}
                                    </h3>
                                </div>
                                <div className="advice__divider">
                                    <div className="dashed-divider"></div>
                                </div>
                                <div className="advice__copy">
                                    <p
                                        className="text-copy text--body"
                                        dangerouslySetInnerHTML={{
                                            __html: e.content,
                                        }}
                                    ></p>
                                </div>
                                <div className="advice__counter">
                                    <span className="text-copy text--primary">
                                        {i + 1}/{data.items.length}
                                    </span>
                                </div>
                            </div>
                            <div className="advice__image-container">
                                <img src={e.image} alt={e.title} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <button
                className="advice-carousel__arrow-right"
                onClick={onNextClick}
            >
                <div className="icon icon--round-arrow-right"></div>
            </button>
        </div>
    );
};
