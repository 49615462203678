import * as React from "react";

export class ScrollListenerBehavior extends React.Component {
    componentDidMount() {
        this.toggleScrolledState();
        window.addEventListener("scroll", this.toggleScrolledState, {
            capture: false,
            passive: true,
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.toggleScrolledState, {
            capture: false,
        });
    }

    toggleScrolledState = () => {
        const isScrolled = this.checkIfScrolled();
        if (window.pageYOffset > 0 && !isScrolled) {
            this.toggleScrolledClass(true);
        }
        if (window.pageYOffset === 0 && isScrolled) {
            this.toggleScrolledClass(false);
        }
    };

    checkIfScrolled = () => {
        if (!document || !document.documentElement) return;

        return document.documentElement.classList.contains("scrolled");
    };

    toggleScrolledClass = (isScrolled: boolean) => {
        const action = isScrolled ? "add" : "remove";

        if (!document || !document.documentElement) return;

        document.documentElement.classList[action]("scrolled");
    };

    render() {
        return null;
    }
}
