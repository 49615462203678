import React, { useContext, FunctionComponent } from "react";

import { ReactComponent as SvgIconRoundHamburger } from "assets/media/icon-round-hamburger.svg";
import { ReactComponent as SvgIconRoundClose } from "assets/media/icon-round-close.svg";
import { AppContext } from "context/AppContext/AppContext";

import navigationData from "assets/data/navigation.json";

type HamburgerType = {
    Button: FunctionComponent;
    Menu: FunctionComponent;
};

function Button() {
    const appContext = useContext(AppContext);

    return (
        <button className="hamburger-button" onClick={appContext.toggleMenu}>
            {appContext.isMenuOpen ? (
                <SvgIconRoundClose className="hamburger-button__icon" />
            ) : (
                <SvgIconRoundHamburger className="hamburger-button__icon" />
            )}
        </button>
    );
}

function Menu() {
    const appContext = useContext(AppContext);

    if (document && document.documentElement) {
        const action = appContext.isMenuOpen ? "add" : "remove";

        if (!document || !document.documentElement) return <></>;

        document.documentElement.classList[action]("hamburger-open");
    }

    const onClick = () => {
        appContext.toggleMenu();
    };

    return (
        <div className="hamburger-menu">
            <div className="hamburger-menu__container">
                <nav className="hamburger-menu__navigation">
                    <ul className="hamburger-navigation">
                        {navigationData &&
                            navigationData.hamburger &&
                            navigationData.hamburger.map((e, i) => (
                                <li
                                    className="hamburger-navigation__item"
                                    key={i}
                                >
                                    <a
                                        onClick={onClick}
                                        className="header-navigation-link"
                                        href={e.href}
                                    >
                                        <i
                                            className={`icon icon--small ${e.icon}`}
                                        ></i>
                                        {e.label}
                                    </a>
                                </li>
                            ))}
                    </ul>
                </nav>
                <div className="hamburger-menu__footer">
                    <a href="https://www.jana-water.com/">
                        <img
                            className="hamburger-footer-logo"
                            src="/assets/media/jana-logo-color.svg"
                            alt="Jana"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

export const Hamburger: HamburgerType = {
    Button,
    Menu,
};
