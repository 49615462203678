import React from "react";

import "assets/styles/app.scss";
import { QuizBlock } from "components/QuizBlock";
import { SocialBlock } from "components/SocialBlock";
import { ScrollListenerBehavior } from "behaviors/ScrollListenerBehavior";
import { Hamburger } from "components/Hamburger";
import { AppProvider } from "context/AppContext";
import { HamburgerListenerBehavior } from "behaviors/HamburgerListenerBehavior";
import { HeroBlock } from "components/HeroBlock";
import { ForewordBlock } from "components/ForewordBlock";

import navigationData from "assets/data/navigation.json";
import { AdviceBlock } from "components/AdviceBlock";
import { FactsBlock } from "components/FactsBlock";

const App = () => {
    return (
        <AppProvider>
            <div className="page-home" id="naslovna">
                <HamburgerListenerBehavior />
                <ScrollListenerBehavior />
                <div className="header">
                    <div className="header__container wrapper">
                        <div className="header__logo">
                            <a href="https://www.jana-water.com/">
                                <img
                                    className="header-logo"
                                    src="/assets/media/jana-logo-color.svg"
                                    alt="Jana"
                                />
                            </a>
                        </div>
                        <div className="header__navigation">
                            <ul className="header-navigation">
                                {navigationData &&
                                    navigationData.header &&
                                    navigationData.header.map((e, i) => (
                                        <li
                                            className="header-navigation__item"
                                            key={i}
                                        >
                                            <a
                                                className="header-navigation-link"
                                                href={e.href}
                                            >
                                                <i
                                                    className={`icon icon--small ${e.icon}`}
                                                ></i>
                                                {e.label}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                            <Hamburger.Button />
                        </div>
                    </div>
                </div>
                <div className="main">
                    <HeroBlock />
                    <ForewordBlock />
                    <AdviceBlock />
                    <QuizBlock />
                    <SocialBlock />
                    <FactsBlock />
                </div>
                <div className="footer">
                    <div className="wrapper">
                        <div className="footer__logo">
                            <a href="https://www.jana-water.com/">
                                <img
                                    src="/assets/media/jana-logo-white.svg"
                                    className="footer-logo"
                                    alt="Jana"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <Hamburger.Menu />
            </div>
        </AppProvider>
    );
};

export { App };
