import React, { FunctionComponent } from "react";
import { Button } from "components/Button";

import data from "assets/data/quiz.json";

type Props = {
    correctGuesses: number;
    totalQuestions: number;
    onRestartClick: () => any;
};

export const QuizResult: FunctionComponent<Props> = (props) => (
    <div className="quiz-result">
        <div className="quiz-result__container">
            <div className="quiz-result__text-container">
                <div className="quiz-result__row">
                    <p className="text-question text--light">
                        {data.result.result_label}
                        {props.correctGuesses}/{props.totalQuestions}
                    </p>
                </div>
                <div className="quiz-result__row">
                    <p className="text-subtitle text--light">
                        {props.correctGuesses / props.totalQuestions >= 0.5
                            ? data.result.title_good
                            : data.result.title_bad}
                    </p>
                </div>
                <div className="quiz-result__row">
                    <p className="text-label text--body-light">
                        {data.result.body}
                    </p>
                </div>
            </div>
            <div className="quiz-result__action-container">
                <div className="quiz-result__action">
                    <Button tag="button" onClick={props.onRestartClick}>
                        {data.result.button_label}
                    </Button>
                </div>
            </div>
        </div>
    </div>
);
