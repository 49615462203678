import React from "react";
import {GalleryGridItem} from "../GalleryGrid/GalleryGridItem";

export interface iCard{
    cardImg: string;
}

interface Props {
    cardProps: iCard[];
}

export const GalleryGrid = (props: Props) => {

    return (
        <div className="gallery-grid">
            {
                props.cardProps.map((item: iCard) => {
                    return (
                        <div className="gallery-grid__item">
                            <GalleryGridItem card={item} />
                        </div>  
                    )
                    
                })
            }
        </div>
    );
};
