import anime, { AnimeParams, AnimeTimelineInstance } from "animejs";
import { UAParser } from "ua-parser-js";

const animateWave = (wave: Element, waveClip: Element) => {
    const path = wave.querySelector("#wave-clip-start");

    const morphPaths = Array.from(
        waveClip.querySelectorAll('[id*="wave-clip-stage-"]'),
    );
    const morphValues = morphPaths.map((e) => e.getAttribute("d"));

    return {
        targets: path,
        d: morphValues,
        easing: "linear",
        duration: 5000,
    };
};

const animateWhale = (whale: Element): AnimeParams => {
    whale.setAttribute("style", "transform:rotate(0deg)");
    return {
        targets: whale,
        translateY: ["-5%", "-15%"],
        loop: true,
        easing: "easeInOutQuad",
        duration: 2500,
        direction: "alternate",
    };
};

export const animate = (element: HTMLDivElement) => {
    const ua = new UAParser();

    const isBrowserSupported =
        ["Chrome", "Firefox"].indexOf(ua.getBrowser().name || "") > -1;

    const wave = element.querySelector(".svg-shape-wave");
    const waveClip = element.querySelector(".svg-wave-data");
    const whale = element.querySelector(".svg-whale");

    let timeline: AnimeTimelineInstance | null = null;

    if (isBrowserSupported && window.innerWidth > 1024) {
        timeline = anime.timeline({
            complete: () => {
                if (!timeline) return;
                timeline.restart();
            },
        });
    }

    if (wave && waveClip) {
        if (isBrowserSupported && window.innerWidth > 1024 && timeline) {
            timeline.add(animateWave(wave, waveClip));
        }
    }

    if (whale) {
        if (isBrowserSupported && window.innerWidth > 1024) {
            anime(animateWhale(whale));
        }
    }
};
