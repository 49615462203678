import React from "react";
import { A11yHide } from "components/A11yHide";

import { ReactComponent as Icon } from "assets/media/icon-round-arrow-right.svg";

type Props = {
    disabled?: boolean;
    currentQuestion: number;
    totalQuestions: number;
    onClick: () => void;
};

export const QuizControl = (props: Props) => (
    <div className="quiz-control">
        <span className="quiz-control__label">
            <A11yHide>Pitanje:</A11yHide>
            <span className="text-label text--primary">
                {props.currentQuestion}/{props.totalQuestions}
            </span>
        </span>
        <button
            className="quiz-control__button"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <A11yHide>Sljedeće pitanje</A11yHide>
            <Icon />
        </button>
    </div>
);
