import anime from "animejs";
import { UAParser } from "ua-parser-js";

const animateHero = (svg: Element) => {
    const path = svg.querySelector("#hero-clip-start");
    const paths = Array.from(svg.querySelectorAll('[id*="hero-clip-stage-"]'));
    const pathValues = paths.map((e) => e.getAttribute("d"));

    const morphPaths = Array.from(
        svg.querySelectorAll('[id*="hero-clip-morph-stage-"]'),
    );
    const morphValues = morphPaths.map((e) => e.getAttribute("d"));

    return {
        targets: path,
        d: pathValues,
        easing: "easeOutSine",
        duration: 1200,
        loop: false,
        complete: () => {
            if (window.innerWidth > 1024) {
                anime({
                    targets: path,
                    d: morphValues,
                    easing: "linear",
                    duration: 5000,
                    loop: true,
                    direction: "alternate",
                });
            }
        },
    };
};

const animatePenguin = (element: Element) => {
    element.setAttribute("style", "transform-origin:50% 100%");
    return {
        targets: element,
        duration: 1200,
        easing: "easeOutCubic",
        translateX: ["-100%", "-50%"],
        rotate: ["-45deg", "0deg"],
    };
};

const animateZebra = (element: Element) => {
    element.setAttribute("style", "transform-origin:50% 100%");
    return {
        targets: element,
        duration: 1200,
        easing: "easeOutCubic",
        scale: ["0", "1"],
        translateX: ["25%", "0"],
        rotate: ["25deg", "0deg"],
    };
};

const animateContent = (elements: Array<Element>) => {
    return {
        targets: elements,
        opacity: ["0", "1"],
        duration: 900,
        delay: anime.stagger(300),
        easing: "easeOutCubic",
    };
};

export const animate = (element: HTMLDivElement) => {
    const ua = new UAParser();

    const isBrowserSupported =
        ["Chrome", "Firefox"].indexOf(ua.getBrowser().name || "") > -1;

    const penguin = element.querySelector(".svg-penguin");
    const zebra = element.querySelector(".svg-zebra");
    const hero = element.querySelector(".svg-hero");
    const contentRows = Array.from(
        element.querySelectorAll(".hero-block__content-container > *"),
    );

    const timeline = anime.timeline();

    if (hero) {
        timeline.add(animateHero(hero), 300);
    }

    if (penguin) {
        if (isBrowserSupported && window.innerWidth > 1024) {
            timeline.add(animatePenguin(penguin), 900);
        }
    }

    if (zebra) {
        if (isBrowserSupported && window.innerWidth > 1024) {
            timeline.add(animateZebra(zebra), 1200);
        }
    }

    if (animateContent) {
        if (isBrowserSupported && window.innerWidth > 1024) {
            timeline.add(animateContent(contentRows), 1200);
        }
    }
};
