import React, { ReactNode, useState, ReactElement } from "react";
import { QuizQuestion } from "components/QuizQuestion";
import { QuizControl } from "components/QuizControl";
import { QuizResult } from "components/QuizResult";

type Props = {
    children: ReactNode;
};

export function Quiz(props: Props) {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [correctGuesses, setCorrectGuesses] = useState(0);
    const [isAnswerSelected, setIsAnswerSelected] = useState(false);

    const QuestionComponent: ReactNode = React.Children.toArray(props.children)[
        questionIndex
    ];

    const currentQuestion = questionIndex + 1;
    const totalQuestions = React.Children.count(props.children);

    const onSelect = (correct: boolean) => {
        if (correct) {
            setCorrectGuesses(correctGuesses + 1);
        }
        setIsAnswerSelected(true);
    };

    const onClick = () => {
        setQuestionIndex(questionIndex + 1);
        setIsAnswerSelected(false);
    };

    const onRestartClick = () => {
        setCorrectGuesses(0);
        setQuestionIndex(0);
        setIsAnswerSelected(false);
    };

    return (
        <div className="quiz">
            {currentQuestion <= totalQuestions && (
                <div className="quiz__question-container">
                    <div className="quiz__question">
                        {props.children &&
                            React.cloneElement(
                                QuestionComponent as ReactElement<any>,
                                {
                                    onSelect,
                                },
                            )}
                    </div>
                    <div className="quiz__controls">
                        <QuizControl
                            disabled={!isAnswerSelected}
                            onClick={onClick}
                            currentQuestion={currentQuestion}
                            totalQuestions={totalQuestions}
                        />
                    </div>
                </div>
            )}
            {currentQuestion > totalQuestions && (
                <div className="quiz__result-container">
                    <QuizResult
                        correctGuesses={correctGuesses}
                        totalQuestions={totalQuestions}
                        onRestartClick={onRestartClick}
                    />
                </div>
            )}
        </div>
    );
}

Quiz.Question = QuizQuestion;
