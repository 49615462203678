import React, { useRef, useEffect } from "react";
import { SvgPenguin } from "components/SvgPenguin";
import { SvgZebra } from "components/SvgZebra";
import { HeroImage } from "components/HeroImage";

import data from "assets/data/hero.json";

import { animate } from "./HeroBlock.animations";

export const HeroBlock = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref || !ref.current) return;

        animate(ref.current);
    }, [ref]);

    return (
        <div ref={ref} className="page-block hero-block">
            <div className="hero-block__decorative-image">
                <SvgPenguin />
            </div>
            <div className="hero-block__container wrapper">
                <div className="hero-block__content-container">
                    <div className="hero-block__tagline">
                        <span className="text-copy text--dark">
                            {data.tagline}
                        </span>
                    </div>
                    <div className="hero-block__title">
                        <h1 className="text-hero-title text--dark">
                            {data.title}
                        </h1>
                    </div>
                    <div className="hero-block__copy">
                        <p
                            className="text-hero-copy text--body"
                            dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                    </div>
                    <div className="hero-block__link">
                        <a className="action-link" href={data.link.href}>
                            {data.link.label}
                        </a>
                    </div>
                </div>
                <div className="hero-block__image-container">
                    <div className="hero-block__hero-image-decoration">
                        <SvgZebra />
                    </div>
                    <div className="hero-block__image">
                        <HeroImage src={data.image} />
                    </div>
                </div>
            </div>
        </div>
    );
};
