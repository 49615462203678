import React from "react";

type Props = {
    src: string;
    ref?: any;
};

export const HeroImage = React.forwardRef((props: Props, ref: any) => (
    <>
        <svg
            ref={ref}
            className="a11y-hide svg-hero"
            width="0"
            height="0"
            viewBox="0 0 296 269"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <clipPath>
                    <path
                        id="hero-clip-morph-stage-1"
                        d="M286.5 104.5C286.5 153.5 251.093 267 178 267C104.907 267 27.3472 232.5 4.99995 151C-17.3473 69.5 67.0596 0 140.153 0C213.246 0 286.5 55.5 286.5 104.5Z"
                        fill="#C90B1C"
                    />
                    <path
                        id="hero-clip-morph-stage-2"
                        d="M284.5 86C295 133.5 268.5 264 170.5 266C72.5 268 22 214 5.49995 173C-11.0001 132 13 8.5 111.5 6.67572e-06C210 -8.49999 274 38.5 284.5 86Z"
                        fill="#C90B1C"
                    />
                    <path
                        id="hero-clip-morph-stage-3"
                        d="M295.5 97C295 137 289.5 266 194 268C98.5 270 17.9822 238 3.99994 180C-9.98229 122 12.5 3.00002 134.5 1.00001C256.5 -0.99999 296 57 295.5 97Z"
                        fill="#C90B1C"
                    />
                </clipPath>
                <clipPath>
                    <path
                        id="hero-clip-stage-4"
                        d="M287.5 133.5C287.5 207.23 228.246 267 155.153 267C82.0596 267 0.500182 155 0.500092 100C0.500001 45 82.0596 0 155.153 0C228.246 0 287.5 59.77 287.5 133.5Z"
                        fill="transparent"
                    />
                </clipPath>
                <clipPath
                    id="hero-image-clip"
                    clipPathUnits="objectBoundingBox"
                    transform="scale(0.00348 0.00375)"
                >
                    <path
                        id="hero-clip-start"
                        d="M149 130C149 131 148 131 148 131C148 131 147 131 147 130C147 129 148 129 148 129C148 129 149 129 149 130Z"
                        fill="transparent"
                    />
                </clipPath>
            </defs>
        </svg>
        <div className="hero-image">
            <img alt="" src={props.src} />
        </div>
    </>
));
